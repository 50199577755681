import axios from "axios";
import { get as safeGet, uniqueId } from "lodash";
import { getFixedT } from "i18next";
import { Base64 } from "js-base64";
import { getCurrentLanguage } from "./languageService";

import { logout } from "./logoutService";
import * as tokenService from "./tokenService";
import { showMessageDialog } from "../components/confirm/confirmDialog";
import RequestQueue from "./requestQueue";

const mediaType = "application/json";

const setAcceptLanguageHeader = (locale = "fi") => ({ "Accept-Language": locale });

const setAuthorizationHeader = () => {
  const token = tokenService.get();

  return token ? { Authorization: `Bearer ${token}` } : {};
};

const setHeaders = ({ acceptLanguage = true, authorization = true, extraHeaders = {} } = {}) => {
  let headers = {};

  // Important side effect note:
  //
  // The HTTP header "Accept-Language" is currently being used to request
  // delivery of various emails matching the current locale of the user
  // interface.
  if (acceptLanguage) {
    headers = { ...headers, ...setAcceptLanguageHeader(getCurrentLanguage()) };
  }

  if (authorization) {
    headers = { ...headers, ...setAuthorizationHeader() };
  }

  return { ...headers, ...extraHeaders };
};

const UNAUTHORIZED = 401;
const GENERIC_NETWORK_ERRORS = ["ECONNREFUSED", "ERR_NETWORK", "ETIMEDOUT"];
const NOT_FOUND_ERROR = "ENOTFOUND";

const apiBaseUrl = import.meta.env.VITE_APP_API_BASE_URL;

if (!apiBaseUrl) {
  throw new Error("VITE_APP_API_BASE_URL must be present");
}

axios.defaults.baseURL = apiBaseUrl;
axios.defaults.timeout = 20000; // 20 seconds
axios.defaults.headers.common.Accept = mediaType;
axios.defaults.headers.common["Content-Type"] = mediaType;

const requestQueue = new RequestQueue(6); // Adjust according to the desired concurrency limit

// Use a request interceptor to queue requests and avoid overloading the browser concurrency limit
axios.interceptors.request.use((config) => {
  // eslint-disable-next-line no-param-reassign
  config.id = uniqueId("axiosRequest_");

  return new Promise((resolve) => {
    requestQueue.enqueue(config, resolve);
  });
});

axios.interceptors.response.use(
  (response) => {
    requestQueue.dequeue(response.config);
    return response;
  },
  (error) => {
    requestQueue.dequeue(error.config);
    return Promise.reject(error);
  },
);

// Hard-catch 401 UNAUTHORIZED errors to easily handle them globally.
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status } = safeGet(error, "response", { status: 0 });
    const code = safeGet(error, "code", null);
    const t = getFixedT(null, "meta");

    if (status === UNAUTHORIZED) {
      logout();
      const followUrl = window.location.pathname + window.location.search;
      window.location.replace(`/login?followUrl=${Base64.encode(followUrl)}`);
      return Promise.resolve({});
    }

    if (code && GENERIC_NETWORK_ERRORS.includes(code)) {
      showMessageDialog({
        title: t("networkErrors.errNetwork.title"),
        message: t("networkErrors.errNetwork.message"),
        confirmButton: t("networkErrors.confirmButton"),
        confirmAction: () => {},
      });
    }

    if (NOT_FOUND_ERROR === code) {
      showMessageDialog({
        title: t("networkErrors.errNotFound.title"),
        message: t("networkErrors.errNotFound.message"),
        confirmButton: t("networkErrors.confirmButton"),
        confirmAction: () => {},
      });
    }

    return Promise.reject(error);
  },
);

// Intercept requests to renew the authorization header token when necessary
axios.interceptors.request.use(
  async (request) => {
    const token = tokenService.get();

    // Do not renew if there is no token (not logged in)
    if (!token) {
      return request;
    }
    const tokenLife = tokenService.tokenLifeSeconds(token);

    // Renew the token if there is less than an hour left
    if (tokenLife > 3600) {
      return request;
    }

    // Avoid multiple renews at the same time
    if (tokenService.getIsRenewingToken()) {
      return request;
    }

    // Renew token before doing the actual request
    try {
      tokenService.setIsRenewingToken(true);
      const data = {};
      const axiosClone = axios.create(); // Prevent interceptors from re-triggering
      const response = await axiosClone.post("/login/user/renew", data, { headers: setHeaders() });
      const newToken = response.data.token;
      tokenService.set(newToken);
    } finally {
      tokenService.setIsRenewingToken(false);
    }

    return request;
  },
  (error) => Promise.reject(error),
);

export const getDefaultInstance = () => axios;

export const get = async ({ url, data, headers = {}, config = {} }) =>
  axios.get(url, {
    headers: setHeaders({ extraHeaders: headers }),
    params: data,
    ...config,
  });

export const post = async ({ url, data, headers = {} }) =>
  axios.post(url, data, { headers: setHeaders({ extraHeaders: headers }) });
export const patch = async ({ url, data, headers = {} }) =>
  axios.patch(url, data, { headers: setHeaders({ extraHeaders: headers }) });
export const put = async ({ url, data, headers = {} }) =>
  axios.put(url, data, { headers: setHeaders({ extraHeaders: headers }) });
export const destroy = async ({ url, data, headers = {} }) =>
  axios.delete(url, { headers: setHeaders({ extraHeaders: headers }), params: data });

export const upload = async ({ url, file, data = {}, headers = {} }) => {
  const allHeaders = setHeaders({
    extraHeaders: { "Content-Type": "multipart/form-data", ...headers },
  });
  const formData = new FormData();
  formData.append("file", file);

  Object.entries(data).forEach(([key, value]) => {
    formData.append(key, value);
  });

  return axios.post(url, formData, { headers: allHeaders });
};

export const composeApiUrl = (url) => {
  let path = url;

  if (url.startsWith("/")) {
    path = url.slice(1);
  }

  return `${apiBaseUrl}/${path}`;
};

export const serverTime = async () => {
  const url = "server/time";
  const clientTime = Date.now();
  const response = await axios.get(url, {
    headers: setHeaders(),
    params: { client_time: clientTime },
  });

  // Not super accurate (does not account for request time) but will serve its purpose
  return { serverTime: response.data.server_time, offset: response.data.time_offset };
};
