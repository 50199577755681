export const localeImports = {
  af: () => import("moment/locale/af"),
  ar: () => import("moment/locale/ar"),
  "ar-dz": () => import("moment/locale/ar-dz"),
  "ar-kw": () => import("moment/locale/ar-kw"),
  "ar-ly": () => import("moment/locale/ar-ly"),
  "ar-ma": () => import("moment/locale/ar-ma"),
  "ar-ps": () => import("moment/locale/ar-ps"),
  "ar-sa": () => import("moment/locale/ar-sa"),
  "ar-tn": () => import("moment/locale/ar-tn"),
  az: () => import("moment/locale/az"),
  be: () => import("moment/locale/be"),
  bg: () => import("moment/locale/bg"),
  bm: () => import("moment/locale/bm"),
  "bn-bd": () => import("moment/locale/bn-bd"),
  bn: () => import("moment/locale/bn"),
  bo: () => import("moment/locale/bo"),
  br: () => import("moment/locale/br"),
  bs: () => import("moment/locale/bs"),
  ca: () => import("moment/locale/ca"),
  cs: () => import("moment/locale/cs"),
  cv: () => import("moment/locale/cv"),
  cy: () => import("moment/locale/cy"),
  da: () => import("moment/locale/da"),
  "de-at": () => import("moment/locale/de-at"),
  "de-ch": () => import("moment/locale/de-ch"),
  de: () => import("moment/locale/de"),
  dv: () => import("moment/locale/dv"),
  el: () => import("moment/locale/el"),
  "en-au": () => import("moment/locale/en-au"),
  "en-ca": () => import("moment/locale/en-ca"),
  "en-gb": () => import("moment/locale/en-gb"),
  "en-ie": () => import("moment/locale/en-ie"),
  "en-il": () => import("moment/locale/en-il"),
  "en-in": () => import("moment/locale/en-in"),
  "en-nz": () => import("moment/locale/en-nz"),
  "en-sg": () => import("moment/locale/en-sg"),
  eo: () => import("moment/locale/eo"),
  "es-do": () => import("moment/locale/es-do"),
  "es-mx": () => import("moment/locale/es-mx"),
  "es-us": () => import("moment/locale/es-us"),
  es: () => import("moment/locale/es"),
  et: () => import("moment/locale/et"),
  eu: () => import("moment/locale/eu"),
  fa: () => import("moment/locale/fa"),
  fi: () => import("moment/locale/fi"),
  fil: () => import("moment/locale/fil"),
  fo: () => import("moment/locale/fo"),
  fr: () => import("moment/locale/fr"),
  "fr-ca": () => import("moment/locale/fr-ca"),
  "fr-ch": () => import("moment/locale/fr-ch"),
  fy: () => import("moment/locale/fy"),
  ga: () => import("moment/locale/ga"),
  gd: () => import("moment/locale/gd"),
  gl: () => import("moment/locale/gl"),
  "gom-deva": () => import("moment/locale/gom-deva"),
  "gom-latn": () => import("moment/locale/gom-latn"),
  gu: () => import("moment/locale/gu"),
  he: () => import("moment/locale/he"),
  hi: () => import("moment/locale/hi"),
  hr: () => import("moment/locale/hr"),
  hu: () => import("moment/locale/hu"),
  hy: () => import("moment/locale/hy-am"),
  id: () => import("moment/locale/id"),
  is: () => import("moment/locale/is"),
  it: () => import("moment/locale/it"),
  "it-ch": () => import("moment/locale/it-ch"),
  ja: () => import("moment/locale/ja"),
  jv: () => import("moment/locale/jv"),
  ka: () => import("moment/locale/ka"),
  kk: () => import("moment/locale/kk"),
  km: () => import("moment/locale/km"),
  kn: () => import("moment/locale/kn"),
  ko: () => import("moment/locale/ko"),
  ku: () => import("moment/locale/ku"),
  "ku-kmr": () => import("moment/locale/ku-kmr"),
  ky: () => import("moment/locale/ky"),
  lb: () => import("moment/locale/lb"),
  lo: () => import("moment/locale/lo"),
  lt: () => import("moment/locale/lt"),
  lv: () => import("moment/locale/lv"),
  me: () => import("moment/locale/me"),
  mi: () => import("moment/locale/mi"),
  mk: () => import("moment/locale/mk"),
  ml: () => import("moment/locale/ml"),
  mn: () => import("moment/locale/mn"),
  mr: () => import("moment/locale/mr"),
  ms: () => import("moment/locale/ms"),
  "ms-my": () => import("moment/locale/ms-my"),
  my: () => import("moment/locale/my"),
  nb: () => import("moment/locale/nb"),
  "nl-be": () => import("moment/locale/nl-be"),
  nl: () => import("moment/locale/nl"),
  nn: () => import("moment/locale/nn"),
  oc: () => import("moment/locale/oc-lnc"),
  pl: () => import("moment/locale/pl"),
  pt: () => import("moment/locale/pt"),
  "pt-br": () => import("moment/locale/pt-br"),
  ro: () => import("moment/locale/ro"),
  ru: () => import("moment/locale/ru"),
  sd: () => import("moment/locale/sd"),
  si: () => import("moment/locale/si"),
  sk: () => import("moment/locale/sk"),
  sl: () => import("moment/locale/sl"),
  sr: () => import("moment/locale/sr"),
  "sr-cyrl": () => import("moment/locale/sr-cyrl"),
  sv: () => import("moment/locale/sv"),
  th: () => import("moment/locale/th"),
  "zh-cn": () => import("moment/locale/zh-cn"),
  "zh-hk": () => import("moment/locale/zh-hk"),
  "zh-mo": () => import("moment/locale/zh-mo"),
  "zh-tw": () => import("moment/locale/zh-tw"),
};
