import { call, delay, put, select, take } from "redux-saga/effects";
import { push } from "redux-first-history";
import { omit, pick, get as safeGet } from "lodash";
import * as api from "./api";
import { documentActions } from "./actions";
import { useCurrentOrganizationId } from "../../utils/sagaUtils";
import { resizeImageBeforeUpload } from "../../utils/imageUtils";
import { logAnalyticsEvent } from "../analytics/analyticsService";
import { documentMatchesScope, extractFilterFromScope } from "./scopes/documentScopes";
import { debugPrint, debugPrintAndLogError } from "../logging/logger";
import { equalDocumentValueLines, getDocumentTotalValue } from "./utils";

function* list(action) {
  try {
    const {
      organizationId = yield useCurrentOrganizationId(),
      filter,
      onCompletion = () => {},
    } = action.payload;
    const response = yield call(api.list, { organizationId, filter });
    yield put(
      documentActions.list.success({
        documents: response.data.documents,
        filter,
      }),
    );

    onCompletion(response.data.documents);
  } catch (error) {
    yield put(documentActions.list.error(error));
    debugPrintAndLogError(error);
  }
}

function* search(action) {
  try {
    const {
      organizationId = yield useCurrentOrganizationId(),
      filters,
      onCompletion = () => {},
    } = action.payload;

    const response = yield call(api.search, { organizationId, filters });
    yield put(documentActions.search.success(response.data));

    onCompletion(response.data);
  } catch (error) {
    debugPrintAndLogError(error);
    yield put(documentActions.search.error(error));
  }
}

function* count(action) {
  try {
    const { organizationId = yield useCurrentOrganizationId(), filter } = action.payload;
    const response = yield call(api.count, { organizationId, filter });
    yield put(
      documentActions.count.success({
        count: response.data.count,
        filter,
      }),
    );
  } catch (error) {
    yield put(documentActions.count.error(error));
    debugPrintAndLogError(error);
  }
}

function* totalValue(action) {
  try {
    const {
      organizationId = yield useCurrentOrganizationId(),
      filter,
      currencyCode = "USD",
    } = action.payload;

    const response = yield call(api.totalValue, {
      organizationId,
      filter,
      currencyCode,
    });

    yield put(
      documentActions.totalValue.success({
        totalValue: response.data.totalValue,
        filter,
      }),
    );
  } catch (error) {
    yield put(documentActions.totalValue.error(error));
    debugPrintAndLogError(error);
  }
}

function* listMeta(action) {
  try {
    const { organizationId = yield useCurrentOrganizationId() } = action.payload;
    const response = yield call(api.listMeta, { organizationId });
    yield put(documentActions.listMeta.success(response.data));
  } catch (error) {
    yield put(documentActions.listMeta.error(error));
    debugPrintAndLogError(error);
  }
}

function* get(action) {
  try {
    const {
      organizationId = yield useCurrentOrganizationId(),
      documentId,
      onSuccess,
    } = action.payload;
    const response = yield call(api.get, { organizationId, documentId });
    yield put(documentActions.get.success(response.data));

    if (onSuccess) {
      onSuccess(response.data.document);
    }
  } catch (error) {
    if (error.response && error.response.status === 404) {
      if (action.payload.onNotFound) {
        action.payload.onNotFound();
      }
    }
    yield put(
      documentActions.get.error({
        documentId: action.payload.documentId,
        error,
      }),
    );
    debugPrintAndLogError(error);
  }
}

function* getList(action) {
  try {
    const { organizationId = yield useCurrentOrganizationId(), documentIds } = action.payload;
    const response = yield call(api.getList, { organizationId, documentIds });
    yield put(documentActions.getList.success(response.data));
  } catch (error) {
    yield put(
      documentActions.getList.error({
        documentIds: action.payload.documentIds,
        error,
      }),
    );
    debugPrintAndLogError(error);
  }
}

function* create(action) {
  try {
    const {
      organizationId = yield useCurrentOrganizationId(),
      documentCategoryId,
      itemCategoryId,
      transactionType,
      name,
      documentTime,
      files = [],
      redirectToView = true,
      redirectSearchPart = "",
      onCompletion = () => {},
      onOcrAnalysisCompletion = () => {},
    } = action.payload;
    // Create the blank document first
    const response = yield call(api.create, {
      organizationId,
      documentCategoryId,
      itemCategoryId,
      name,
      documentTime,
      transactionType,
    });
    const documentId = response.data.document.id;

    // Upload the files
    let i = 0;
    // eslint-disable-next-line no-restricted-syntax
    for (const file of files) {
      yield put(
        documentActions.upload.request({
          organizationId,
          documentId,
          file,
          index: i,
          startOcrAnalysisJob: i === 0,
          onOcrAnalysisCompletion,
        }),
      );
      yield take([documentActions.upload.success, documentActions.upload.error]);
      i += 1;
    }

    yield put(documentActions.create.success(response.data));

    logAnalyticsEvent("document_created");

    const { document } = response.data;
    yield call(updateScopedDocumentCollections, { document, organizationId });

    if (redirectToView) {
      yield put(push(`/dashboard/documents/edit/${documentId}?${redirectSearchPart}`));
    }

    onCompletion(document);
  } catch (error) {
    const { onError = () => {} } = action.payload;

    yield put(documentActions.create.error({ error }));
    debugPrintAndLogError(error);
    onError(error);
  }
}

function updatedOcrRecognizedFields(oldDocument, updatedParams) {
  let ocrRecognizedFields = [...(oldDocument.ocrRecognizedFields || [])];

  if (updatedParams.name && updatedParams.name !== oldDocument.name) {
    ocrRecognizedFields = ocrRecognizedFields.filter((f) => !["name"].includes(f));
  }
  if (
    updatedParams.documentValueLines &&
    !equalDocumentValueLines(updatedParams.documentValueLines, oldDocument.documentValueLines)
  ) {
    ocrRecognizedFields = ocrRecognizedFields.filter(
      (f) => !["total", "tax_rate", "tax_amount"].includes(f),
    );
  }
  if (updatedParams.currencyCode && updatedParams.currencyCode !== oldDocument.currencyCode) {
    ocrRecognizedFields = ocrRecognizedFields.filter((f) => !["currency"].includes(f));
  }
  if (updatedParams.documentTime && updatedParams.documentTime !== oldDocument.documentTime) {
    ocrRecognizedFields = ocrRecognizedFields.filter((f) => !["document_time"].includes(f));
  }
  if (
    updatedParams.paymentMethodId &&
    updatedParams.paymentMethodId !== oldDocument.paymentMethodId
  ) {
    ocrRecognizedFields = ocrRecognizedFields.filter((f) => !["payment_method"].includes(f));
  }
  if (
    updatedParams.transactionType &&
    updatedParams.transactionType !== oldDocument.transactionType
  ) {
    ocrRecognizedFields = ocrRecognizedFields.filter((f) => !["transaction_type"].includes(f));
  }
  if (updatedParams.itemCategoryId && updatedParams.itemCategoryId !== oldDocument.itemCategoryId) {
    ocrRecognizedFields = ocrRecognizedFields.filter((f) => !["item_category"].includes(f));
  }
  if (
    updatedParams.referenceNumber &&
    updatedParams.referenceNumber !== oldDocument.referenceNumber
  ) {
    ocrRecognizedFields = ocrRecognizedFields.filter((f) => !["reference_number"].includes(f));
  }

  if (
    updatedParams.documentCategoryId &&
    updatedParams.documentCategoryId !== oldDocument.documentCategoryId
  ) {
    ocrRecognizedFields = ocrRecognizedFields.filter(
      (f) => !["document_category", "item_category"].includes(f),
    );
  }

  return ocrRecognizedFields;
}

function* update(action) {
  try {
    const {
      organizationId = yield useCurrentOrganizationId(),
      documentId,
      name,
      description,
      documentTime,
      documentCategoryId,
      paymentMethodId,
      referenceNumber,
      customExchangeRate,
      customExchangeRateCurrency,
      clearCustomExchangeRates,
      skipDuplicateCheck,
      itemCategoryId,
      transactionType,
      taxMode,
      tipAmount,
      reviewStatus,
      currencyCode,
      documentValueLines,
      hashTags,
      onCompletion = () => {},
    } = action.payload;

    let oldDocument = yield select((state) => state.documents.collections.byId[documentId]);

    if (!oldDocument) {
      yield call(get, { payload: { organizationId, documentId } });
      oldDocument = yield select((state) => state.documents.collections.byId[documentId]);
    }
    if (!oldDocument) {
      throw new Error(`Document with id = ${documentId} not found`);
    }

    const ocrRecognizedFields = updatedOcrRecognizedFields(oldDocument, action.payload);

    const response = yield call(api.patch, {
      organizationId,
      documentId,
      name,
      referenceNumber,
      description,
      documentTime,
      documentCategoryId,
      paymentMethodId,
      clearCustomExchangeRates,
      customExchangeRate,
      customExchangeRateCurrency,
      skipDuplicateCheck,
      itemCategoryId,
      transactionType,
      taxMode,
      tipAmount,
      reviewStatus,
      currencyCode,
      documentValueLines,
      hashTags,
      ocrRecognizedFields,
    });

    logAnalyticsEvent("document_edit");

    yield put(documentActions.update.success(response.data));

    const { document } = response.data;

    yield call(updateScopedDocumentCollections, {
      oldDocument,
      document,
      organizationId,
    });

    onCompletion(document);
  } catch (error) {
    const { onError = () => {} } = action.payload;
    yield put(
      documentActions.update.error({
        documentId: action.payload.documentId,
        error,
      }),
    );
    debugPrintAndLogError(error);
    onError(error, action.payload.documentId);
  }
}

function* updateMultiple(action) {
  try {
    const {
      organizationId = yield useCurrentOrganizationId(),
      documentIds,
      onCompletion = () => {},
    } = action.payload;

    const oldDocuments = yield select((state) =>
      pick(state.documents.collections.byId, documentIds),
    );

    const response = yield call(api.patchMultiple, {
      organizationId,
      documentIds,
      ...omit(action.payload, ["documentIds", "onCompletion", "onError"]),
    });

    logAnalyticsEvent("document_edit_multiple");

    yield put(
      documentActions.updateMultiple.success({
        ...response.data,
        restoreAction: !action.payload.trashed,
        trashAction: action.payload.trashed,
      }),
    );

    const { documents } = response.data;

    for (let i = 0; i < documents.length; i += 1) {
      const document = documents[i];
      const oldDocument = oldDocuments[document.id];
      yield call(updateScopedDocumentCollections, {
        oldDocument,
        document,
        organizationId,
      });
    }

    onCompletion(response.data);
  } catch (error) {
    const { documentIds, onError = () => {} } = action.payload;

    yield put(
      documentActions.updateMultiple.error({
        documentIds: action.payload.documentIds,
        error,
      }),
    );
    debugPrintAndLogError(error);
    onError({ documentIds, error });
  }
}

function* moveToOrganization(action) {
  try {
    const {
      organizationId = yield useCurrentOrganizationId(),
      documentId,
      targetOrganizationId,
      onCompletion = () => {},
    } = action.payload;

    const response = yield call(api.moveToOrganization, {
      organizationId,
      documentId,
      targetOrganizationId,
    });

    logAnalyticsEvent("document_moved_to_organization");

    yield put(documentActions.moveToOrganization.success(response.data));
    onCompletion(response.data);
  } catch (error) {
    const { onError = () => {} } = action.payload;
    yield put(
      documentActions.moveToOrganization.error({
        documentId: action.payload.documentId,
        error,
      }),
    );
    debugPrintAndLogError(error);
    onError(error, action.payload.documentId);
  }
}

function* trash(action) {
  try {
    const {
      organizationId = yield useCurrentOrganizationId(),
      documentId,
      redirectTo,
      onCompletion = () => {},
    } = action.payload;
    const oldDocument = yield select((state) => state.documents.collections.byId[documentId]);
    const response = yield call(api.patch, {
      organizationId,
      documentId,
      trashed: true,
    });
    yield put(documentActions.trash.success(response.data));

    logAnalyticsEvent("document_trashed");

    const { document } = response.data;
    yield call(updateScopedDocumentCollections, {
      oldDocument,
      document,
      organizationId,
    });

    if (redirectTo) {
      yield put(push(redirectTo));
    }

    onCompletion(document);
  } catch (error) {
    const { onError = () => {} } = action.payload;
    yield put(
      documentActions.trash.error({
        documentId: action.payload.documentId,
        error,
      }),
    );
    debugPrintAndLogError(error);
    onError(error);
  }
}

function* restore(action) {
  try {
    const {
      organizationId = yield useCurrentOrganizationId(),
      documentId,
      onCompletion = () => {},
    } = action.payload;
    const oldDocument = yield select((state) => state.documents.collections.byId[documentId]);
    const response = yield call(api.patch, {
      organizationId,
      documentId,
      trashed: false,
    });
    yield put(documentActions.restore.success(response.data));

    const { document } = response.data;
    yield call(updateScopedDocumentCollections, {
      oldDocument,
      document,
      organizationId,
    });

    onCompletion(document, true);

    logAnalyticsEvent("document_restored");
  } catch (error) {
    const { onError = () => {} } = action.payload;
    yield put(
      documentActions.restore.error({
        documentId: action.payload.documentId,
        error,
      }),
    );
    debugPrintAndLogError(error);
    onError(error);
  }
}

function* upload(action) {
  try {
    const {
      organizationId = yield useCurrentOrganizationId(),
      documentId,
      file,
      index,
      startOcrAnalysisJob = true,
      onCompletion = () => {},
      onOcrAnalysisCompletion = () => {},
    } = action.payload;

    let processedFile = file;

    if (file.type.includes("image")) {
      const blob = yield resizeImageBeforeUpload(file, {
        width: 2000,
        height: 2000,
      });
      processedFile = new File([blob], file.name, { type: file.type });
    }

    const response = yield call(api.upload, {
      organizationId,
      documentId,
      file: processedFile,
      index,
    });

    logAnalyticsEvent("document_file_uploaded");

    yield put(
      documentActions.upload.success({
        document: response.data.document,
        file,
      }),
    );

    // Start the OCR analysis job for the upload
    if (startOcrAnalysisJob) {
      yield put(
        documentActions.ocrAnalysis.begin.request({
          organizationId,
          documentFileId: response.data.documentFile.id,
          onCompletion: onOcrAnalysisCompletion,
        }),
      );
    }

    onCompletion({ document: response.data.document, file });
  } catch (error) {
    yield put(documentActions.upload.error({ file: action.payload.file, error }));
    debugPrintAndLogError(error);
  }
}

function* replaceFile(action) {
  try {
    const {
      organizationId = yield useCurrentOrganizationId(),
      documentId,
      documentFileId,
      file,
    } = action.payload;

    const response = yield call(api.replaceFile, {
      organizationId,
      documentId,
      documentFileId,
      file,
    });

    logAnalyticsEvent("document_file_replaced");

    yield put(
      documentActions.replaceFile.success({
        document: response.data.document,
        file,
      }),
    );
  } catch (error) {
    debugPrintAndLogError(error);
    yield put(documentActions.replaceFile.error(error));
  }
}

function* uploadProcessedFile(action) {
  try {
    const {
      organizationId = yield useCurrentOrganizationId(),
      documentId,
      documentFileId,
      file,
      filterType,
      cropType,
      cropPoints,
      cwRotations,
    } = action.payload;

    yield put(
      documentActions.processFile.processUpdate({
        documentFileId,
        status: "uploading",
      }),
    );

    const response = yield call(api.uploadProcessedFile, {
      organizationId,
      documentId,
      documentFileId,
      file,
      filterType,
      cropType,
      cropPoints,
      cwRotations,
    });

    yield put(
      documentActions.uploadProcessedFile.success({
        document: response.data.document,
        file,
      }),
    );

    logAnalyticsEvent("processed_file_upload");

    yield put(
      documentActions.processFile.processUpdate({
        documentFileId,
        status: "uploaded",
      }),
    );
  } catch (error) {
    yield put(documentActions.uploadProcessedFile.error(error));
    debugPrintAndLogError(error);
  }
}

function* deleteFile(action) {
  try {
    const {
      organizationId = yield useCurrentOrganizationId(),
      documentId,
      documentFileId,
    } = action.payload;

    const response = yield call(api.deleteFile, {
      organizationId,
      documentId,
      documentFileId,
    });

    logAnalyticsEvent("document_file_deleted");

    yield put(documentActions.deleteFile.success(response.data));
  } catch (error) {
    yield put(
      documentActions.deleteFile.error({
        documentId: action.payload.documentId,
        documentFileId: action.payload.documentFileId,
        error,
      }),
    );
  }
}

function* destroy(action) {
  try {
    const {
      organizationId = yield useCurrentOrganizationId(),
      documentId,
      requireTrashed = true,
      redirectTo,
    } = action.payload;

    const response = yield call(api.destroy, {
      organizationId,
      documentId,
      requireTrashed,
    });
    yield put(documentActions.destroy.success(response.data));

    logAnalyticsEvent("document_destroyed");

    if (redirectTo) {
      yield put(push(redirectTo));
    }
  } catch (error) {
    yield put(
      documentActions.destroy.error({
        documentId: action.payload.documentId,
        error,
      }),
    );
    debugPrintAndLogError(error);
  }
}

function* destroyList(action) {
  try {
    const {
      organizationId = yield useCurrentOrganizationId(),
      documentIds = [],
      requireTrashed = true,
    } = action.payload;

    logAnalyticsEvent("document_destroy_list");

    const response = yield call(api.destroyList, {
      organizationId,
      documentIds,
      requireTrashed,
    });
    yield put(documentActions.destroyList.success(response.data));
  } catch (error) {
    yield put(
      documentActions.destroyList.error({
        documentIds: action.payload.documentIds,
        error,
      }),
    );
    debugPrintAndLogError(error);
  }
}

const ocrAnalysis = {
  *begin(action) {
    try {
      const {
        organizationId = yield useCurrentOrganizationId(),
        documentFileId,
        onCompletion = () => {},
      } = action.payload;
      const response = yield call(api.beginOcrAnalysis, {
        organizationId,
        documentFileId,
      });
      yield put(documentActions.ocrAnalysis.begin.success(response));

      const { task } = response.data;

      let statusResponse = yield call(ocrAnalysis.status, {
        payload: { organizationId, documentFileId, ocrAnalysisTaskId: task.id },
      });

      let taskStatus = safeGet(statusResponse, "data.task.status", "queryFailed");

      while (!["completed", "failed"].includes(taskStatus)) {
        yield delay(2000); // delay for 2 seconds before next poll
        statusResponse = yield call(ocrAnalysis.status, {
          payload: {
            organizationId,
            documentFileId,
            ocrAnalysisTaskId: task.id,
          },
        });
        taskStatus = safeGet(statusResponse, "data.task.status", "queryFailed");
      }

      const isSuccess = taskStatus === "success";
      const resultDocument = statusResponse.data.document;

      if (statusResponse.data.document) {
        yield put(documentActions.update.success({ document: resultDocument }));
      }

      if (isSuccess) {
        yield put(
          documentActions.ocrAnalysis.completed.success({
            document: resultDocument,
          }),
        );
      } else {
        yield put(documentActions.ocrAnalysis.completed.error());
      }

      onCompletion({
        success: taskStatus === "completed",
        task,
        document: resultDocument,
      });
    } catch (error) {
      yield put(documentActions.ocrAnalysis.begin.error(error));
      debugPrintAndLogError(error);
      yield delay(3000); // delay for 3 seconds before restarting
      yield put(documentActions.ocrAnalysis.begin.request(action.payload));
    }
  },
  *status(action) {
    try {
      const {
        organizationId = yield useCurrentOrganizationId(),
        documentFileId,
        ocrAnalysisTaskId,
      } = action.payload;
      const response = yield call(api.ocrAnalysisStatus, {
        organizationId,
        documentFileId,
        ocrAnalysisTaskId,
      });
      yield put(documentActions.ocrAnalysis.status.success(response));
      return response;
    } catch (error) {
      yield put(documentActions.ocrAnalysis.status.error(error));
      debugPrintAndLogError(error);
      return null;
    }
  },
};

function* updateScopedDocumentCollections({ oldDocument = null, document, organizationId }) {
  // Affected scopes
  const scopedTotalValues = yield select((state) => state.documents.collections.totalValues);
  const scopedCounts = yield select((state) => state.documents.collections.counts);
  const currencyCode = yield select(
    (state) => state.login.collections.loginInfo.user.preferredCurrency,
  );

  const totalValueScopes = Object.keys(scopedTotalValues);
  const countScopes = Object.keys(scopedCounts);

  const getDocumentChanges = (scope) => {
    const didMatchScope = oldDocument && documentMatchesScope(oldDocument, scope);
    const willMatchScope = documentMatchesScope(document, scope);
    const scopeChanged = didMatchScope !== willMatchScope;

    const oldTotalValue = oldDocument ? getDocumentTotalValue(oldDocument) : null;
    const newTotalValue = getDocumentTotalValue(document);
    const oldCurrency = oldDocument ? oldDocument.currencyCode : null;
    const newCurrency = document.currencyCode;
    const totalValueChanged = oldTotalValue !== newTotalValue || oldCurrency !== newCurrency;

    return {
      scopeChanged,
      totalValueChanged,
      hasChanges: scopeChanged || totalValueChanged,
    };
  };

  for (let i = 0; i < totalValueScopes.length; i += 1) {
    const scope = totalValueScopes[i];
    debugPrint(scope, document);

    if (getDocumentChanges(scope).hasChanges) {
      yield put(
        documentActions.totalValue.request({
          organizationId,
          scope,
          filter: extractFilterFromScope(scope),
          currencyCode,
        }),
      );
    }
  }

  for (let i = 0; i < countScopes.length; i += 1) {
    const scope = countScopes[i];
    debugPrint(scope, document);

    if (getDocumentChanges(scope).hasChanges) {
      yield put(
        documentActions.count.request({
          organizationId,
          scope,
          filter: extractFilterFromScope(scope),
          currencyCode,
        }),
      );
    }
  }
}

const documentSagas = {
  list,
  search,
  count,
  totalValue,
  listMeta,
  get,
  getList,
  create,
  update,
  updateMultiple,
  moveToOrganization,
  trash,
  restore,
  upload,
  replaceFile,
  uploadProcessedFile,
  deleteFile,
  destroy,
  destroyList,
  updateScopedDocumentCollections,
  ocrAnalysis,
};

export { documentSagas };
