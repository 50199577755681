import { createAction } from "redux-actions";

const namespaces = {
  accountingIntegration: "ACCOUNTING-INTEGRATION",
};

export const accountingIntegrationActions = {
  updateSettings: {
    request: createAction(`${namespaces.accountingIntegration}/UPDATE-SETTINGS/REQUEST`),
    success: createAction(`${namespaces.accountingIntegration}/UPDATE-SETTINGS/SUCCESS`),
    error: createAction(`${namespaces.accountingIntegration}/UPDATE-SETTINGS/ERROR`),
  },
  syncAccountingIntegrationData: {
    request: createAction(`${namespaces.accountingIntegration}/SYNC-INTEGRATION-DATA/REQUEST`),
    queued: createAction(`${namespaces.accountingIntegration}/SYNC-INTEGRATION-DATA/QUEUED`),
    success: createAction(`${namespaces.accountingIntegration}/SYNC-INTEGRATION-DATA/SUCCESS`),
    error: createAction(`${namespaces.accountingIntegration}/SYNC-INTEGRATION-DATA/ERROR`),
  },
  syncAccountingIntegrationStatus: {
    request: createAction(`${namespaces.accountingIntegration}/SYNC-INTEGRATION-STATUS/REQUEST`),
    success: createAction(`${namespaces.accountingIntegration}/SYNC-INTEGRATION-STATUS/SUCCESS`),
    error: createAction(`${namespaces.accountingIntegration}/SYNC-INTEGRATION-STATUS/ERROR`),
  },
  validatePublishDocuments: {
    request: createAction(`${namespaces.accountingIntegration}/VALIDATE-PUBLISH-DOCUMENTS/REQUEST`),
    success: createAction(`${namespaces.accountingIntegration}/VALIDATE-PUBLISH-DOCUMENTS/SUCCESS`),
    error: createAction(`${namespaces.accountingIntegration}/VALIDATE-PUBLISH-DOCUMENTS/ERROR`),
  },
  publishDocuments: {
    request: createAction(`${namespaces.accountingIntegration}/PUBLISH-DOCUMENTS/REQUEST`),
    queued: createAction(`${namespaces.accountingIntegration}/PUBLISH-DOCUMENTS/QUEUED`),
    success: createAction(`${namespaces.accountingIntegration}/PUBLISH-DOCUMENTS/SUCCESS`),
    error: createAction(`${namespaces.accountingIntegration}/PUBLISH-DOCUMENTS/ERROR`),
  },
  publishDocumentStatus: {
    request: createAction(`${namespaces.accountingIntegration}/PUBLISH-DOCUMENT-STATUS/REQUEST`),
    success: createAction(`${namespaces.accountingIntegration}/PUBLISH-DOCUMENT-STATUS/SUCCESS`),
    error: createAction(`${namespaces.accountingIntegration}/PUBLISH-DOCUMENT-STATUS/ERROR`),

    byDocument: {
      request: createAction(
        `${namespaces.accountingIntegration}/PUBLISH-DOCUMENT-STATUS/BY-DOCUMENT/REQUEST`,
      ),
      success: createAction(
        `${namespaces.accountingIntegration}/PUBLISH-DOCUMENT-STATUS/BY-DOCUMENT/SUCCESS`,
      ),
      error: createAction(
        `${namespaces.accountingIntegration}/PUBLISH-DOCUMENT-STATUS/BY-DOCUMENT/ERROR`,
      ),
    },

    monitor: {
      start: createAction(`${namespaces.accountingIntegration}/PUBLISH-DOCUMENT/MONITOR/START`),
      stop: createAction(`${namespaces.accountingIntegration}/PUBLISH-DOCUMENT/MONITOR/STOP`),
    },
  },
  removePublishedDocuments: {
    request: createAction(`${namespaces.accountingIntegration}/REMOVE-PUBLISHED-DOCUMENTS/REQUEST`),
    success: createAction(`${namespaces.accountingIntegration}/REMOVE-PUBLISHED-DOCUMENTS/SUCCESS`),
    error: createAction(`${namespaces.accountingIntegration}/REMOVE-PUBLISHED-DOCUMENTS/ERROR`),
  },
};
