import { all, call, delay, put, take } from "redux-saga/effects";
import { chunk, get, uniqueId } from "lodash";
import * as api from "./api";
import { documentMassUploadActions } from "./actions";
import { useCurrentOrganizationId } from "../../utils/sagaUtils";
import { debugPrintAndLogError } from "../logging/logger";

export const documentMassUploadSagas = {
  *list(action) {
    try {
      const {
        organizationId = yield useCurrentOrganizationId(),
        page = 1,
        pageSize = 10,
        loadAll = false,
        onCompletion,
      } = action.payload || {};

      let response = null;

      let currentPage = page;
      let continueLoading = true;

      while (
        continueLoading &&
        (response === null || response.data.page < response.data.totalPages)
      ) {
        response = yield call(api.listDocumentMassUploadTasks, {
          organizationId,
          page: currentPage,
          pageSize,
        });

        currentPage += 1;
        continueLoading = loadAll && response.data.page < response.data.totalPages;
      }

      yield put(documentMassUploadActions.list.success(response.data));

      if (onCompletion) {
        onCompletion(response.data);
      }
    } catch (error) {
      debugPrintAndLogError(error);
      yield put(documentMassUploadActions.list.error(error));
    }
  },

  *create(action) {
    try {
      const { organizationId = yield useCurrentOrganizationId(), documentCategoryId = null } =
        action.payload;

      const response = yield call(api.createDocumentMassUploadTask, {
        organizationId,
        documentCategoryId,
      });

      yield put(documentMassUploadActions.create.success(response.data));
    } catch (error) {
      debugPrintAndLogError(error);
      yield put(documentMassUploadActions.create.error(error));
    }
  },

  *uploadFile(action) {
    try {
      const {
        organizationId = yield useCurrentOrganizationId(),
        taskId,
        file,
        fileHandle = uniqueId(),
        onCompletion = () => {},
      } = action.payload;

      const response = yield call(api.uploadDocumentMassUploadTaskFile, {
        organizationId,
        taskId,
        file,
      });

      yield put(documentMassUploadActions.uploadFile.success({ ...response.data, fileHandle }));

      onCompletion({ data: response.data, file, fileHandle });
    } catch (error) {
      debugPrintAndLogError(error);
      yield put(
        documentMassUploadActions.uploadFile.error({
          error,
          file: action.payload.file,
          fileHandle: action.payload.fileHandle,
        }),
      );

      if (action.payload.onError) {
        action.payload.onError({
          error,
          file: action.payload.file,
          fileHandle: action.payload.fileHandle,
        });
      }
    }
  },

  *beginTask(action) {
    try {
      const { organizationId = yield useCurrentOrganizationId(), taskId } = action.payload;

      const response = yield call(api.beginDocumentMassUploadTask, {
        organizationId,
        taskId,
      });

      yield put(documentMassUploadActions.monitorTask.request({ organizationId, taskId }));
      yield put(documentMassUploadActions.beginTask.success(response.data));
    } catch (error) {
      debugPrintAndLogError(error);
      yield put(documentMassUploadActions.beginTask.error(error));
    }
  },

  *prepareMassUploadTask(action) {
    try {
      const {
        organizationId = yield useCurrentOrganizationId(),
        documentCategoryId = null,
        files = [],
        onCreated = () => {},
        onFileUploaded = () => {},
        onFileUploadError = () => {},
        onCompletion = () => {},
      } = action.payload;

      yield put(documentMassUploadActions.create.request({ organizationId, documentCategoryId }));

      const taskAction = yield take(documentMassUploadActions.create.success);
      const { task } = taskAction.payload;

      onCreated(task);

      const fileChunks = chunk(files, 5);

      for (let i = 0; i < fileChunks.length; i += 1) {
        yield all(
          fileChunks[i].map(function* uploadSingleFile(file) {
            const uploadHandle = uniqueId("massUploadFile_");

            yield put(
              documentMassUploadActions.uploadFile.request({
                organizationId,
                taskId: task.id,
                file,
                fileHandle: uploadHandle,
                onCompletion: onFileUploaded,
                onError: onFileUploadError,
              }),
            );
            yield take(
              (a) =>
                [
                  documentMassUploadActions.uploadFile.success.toString(),
                  documentMassUploadActions.uploadFile.error.toString(),
                ].includes(a.type) && a.payload.fileHandle === uploadHandle,
            );
          }),
        );
      }

      yield put(documentMassUploadActions.prepareMassUploadTask.success(task));

      onCompletion(task);
    } catch (error) {
      debugPrintAndLogError(error);
      yield put(documentMassUploadActions.prepareMassUploadTask.error());

      if (action.payload.onError) {
        action.payload.onError(error);
      }
    }
  },

  *getTask(action) {
    try {
      const { organizationId = yield useCurrentOrganizationId(), taskId } = action.payload;

      const response = yield call(api.getDocumentMassUploadTask, {
        organizationId,
        taskId,
      });

      yield put(documentMassUploadActions.get.success(response.data));
    } catch (error) {
      debugPrintAndLogError(error);
      yield put(documentMassUploadActions.get.error(error));
    }
  },

  *monitorTask(action) {
    try {
      const { organizationId = yield useCurrentOrganizationId(), taskId } = action.payload;

      while (true) {
        const response = yield call(api.getDocumentMassUploadTask, {
          organizationId,
          taskId,
        });

        yield put(documentMassUploadActions.get.success(response.data));

        if (["completed", "failed"].includes(get(response.data, "task.status"))) {
          break;
        }

        yield delay(3000);
      }
    } catch (error) {
      debugPrintAndLogError(error);
      yield put(documentMassUploadActions.get.error(error));
    }
  },

  *destroy(action) {
    try {
      const {
        organizationId = yield useCurrentOrganizationId(),
        taskId,
        trashDocuments = false,
      } = action.payload;

      yield call(api.destroyDocumentMassUploadTask, {
        organizationId,
        taskId,
        trashDocuments,
      });

      yield put(documentMassUploadActions.destroy.success({ id: taskId }));
    } catch (error) {
      debugPrintAndLogError(error);
      yield put(documentMassUploadActions.destroy.error(error));
    }
  },
};
