import { get, isEmpty, isEqual } from "lodash";
import { debugPrint, debugPrintAndLogError } from "../logging/logger";
import { configuration } from "../../config";

const pixelLibraryUrl = "https://connect.facebook.net/en_US/fbevents.js";

let advancedMatchingParams = null;

export function initializeFacebookPixel() {
  if (typeof window === "undefined") {
    return;
  }

  if (window.fbq) {
    return;
  }

  getOrInitializeFbc();

  // eslint-disable-next-line func-names
  window.fbq = function () {
    // eslint-disable-next-line no-unused-expressions
    window.fbq.callMethod
      ? // eslint-disable-next-line prefer-spread, prefer-rest-params
        window.fbq.callMethod.apply(window.fbq, arguments)
      : // eslint-disable-next-line prefer-rest-params
        window.fbq.queue.push(arguments);
  };

  if (!window._fbq) {
    window._fbq = window.fbq;
  }

  window.fbq.push = window.fbq;
  window.fbq.loaded = true;
  window.fbq.version = "2.0";
  window.fbq.queue = [];

  const script = document.createElement("script");
  script.async = true;
  script.src = pixelLibraryUrl;
  const firstScript = document.getElementsByTagName("script")[0];
  firstScript.parentNode.insertBefore(script, firstScript);

  window.fbq("init", import.meta.env.VITE_APP_FB_PIXEL_ID);
  window.fbq("track", "PageView");
}

export function enableAdvancedMatching(userData = {}) {
  const combinedUserData = { ...userData };
  const fbc = getOrInitializeFbc();

  if (fbc) {
    combinedUserData.fbc = fbc;
  }

  if (isEmpty(combinedUserData)) {
    debugPrintAndLogError(new Error("Advanced matching data is empty."));
    return;
  }

  if (advancedMatchingParams && isEqual(advancedMatchingParams, combinedUserData)) {
    debugPrint("Info: Advanced matching is already enabled with the same data.");
    return;
  }

  if (typeof window === "undefined" || !window.fbq) {
    debugPrintAndLogError(new Error("Meta Pixel is not initialized."));
    return;
  }

  // Add advanced matching data
  window.fbq("init", import.meta.env.VITE_APP_FB_PIXEL_ID, combinedUserData);

  advancedMatchingParams = combinedUserData;

  debugPrint(
    `Info: Advanced matching is enabled with the following data: ${JSON.stringify(
      combinedUserData,
    )}`,
  );
}

export function disableAdvancedMatching() {
  if (typeof window === "undefined" || !window.fbq) {
    return;
  }

  // Disable advanced matching
  window.fbq("init", import.meta.env.VITE_APP_FB_PIXEL_ID);
}

export function logMetaPixelEvent(eventName, eventData = {}) {
  if (typeof window === "undefined" || !window.fbq) {
    debugPrintAndLogError(new Error("Meta Pixel is not initialized."));
    return;
  }

  window.fbq("track", eventName, eventData);
}

export function logSignUp() {
  try {
    logMetaPixelEvent("CompleteRegistration", {
      value: configuration.metaPixel.register.value,
      currency: "USD",
    });
    debugPrint("Info: CompleteRegistration event is logged.");
  } catch (error) {
    debugPrintAndLogError(error);

    if (import.meta.env.DEV) {
      throw error;
    }
  }
}

export function logStartTrial() {
  try {
    logMetaPixelEvent("StartTrial", {
      value: configuration.metaPixel.startTrial.value,
      currency: "USD",
    });
    debugPrint("Info: StartTrial event is logged.");
  } catch (error) {
    debugPrintAndLogError(error);

    if (import.meta.env.DEV) {
      throw error;
    }
  }
}

export function logPurchaseIfNeeded(checkoutResponse) {
  try {
    if (get(checkoutResponse, "data.productType") !== "subscription") {
      return;
    }

    logMetaPixelEvent("Purchase", {
      value: configuration.metaPixel.purchase.value,
      currency: "USD",
    });

    debugPrint("Info: Purchase event is logged.");
  } catch (error) {
    debugPrintAndLogError(error);

    if (import.meta.env.DEV) {
      throw error;
    }
  }
}

function getOrInitializeFbc() {
  const localStorageKey = "metaPixelFbc";

  debugPrint("Info: Trying to get or initialize fbc parameter.");

  // Check if fbc is already in localStorage
  let fbc = localStorage.getItem(localStorageKey);
  if (fbc) {
    debugPrint(`Info: Found fbc parameter in localStorage: ${fbc}`);
    return fbc; // Already initialized
  }

  // Try to extract fbc from cookies or URL
  const cookieMatch = document.cookie.match(/(^|;) ?_fbc=([^;]*)(;|$)/);
  if (cookieMatch) {
    // eslint-disable-next-line prefer-destructuring
    fbc = cookieMatch[2];
  } else {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("fbc") || urlParams.has("fbclid")) {
      fbc = urlParams.get("fbc") || urlParams.get("fbclid");
    }
  }

  // If found, store it in localStorage
  if (fbc) {
    localStorage.setItem(localStorageKey, fbc);
    debugPrint(`Initialized fbc parameter: ${fbc}`);
  }

  return fbc;
}
