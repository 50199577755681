import { call, put } from "redux-saga/effects";
import * as api from "./api";

import { useCurrentOrganizationId } from "../../utils/sagaUtils";
import { transactionLineCollectionActions } from "./actions";
import { debugPrintAndLogError } from "../logging/logger";

export const transactionLineCollectionSagas = {
  *list(action) {
    try {
      const { organizationId = yield useCurrentOrganizationId() } = action.payload;
      const response = yield call(api.list, { organizationId });
      yield put(transactionLineCollectionActions.list.success(response.data));
    } catch (error) {
      yield put(transactionLineCollectionActions.list.error(error));
      debugPrintAndLogError(error);
    }
  },
  *find(action) {
    try {
      const { organizationId = yield useCurrentOrganizationId(), id } = action.payload;
      const response = yield call(api.find, { organizationId, id });
      yield put(transactionLineCollectionActions.show.byId.success(response.data));
    } catch (error) {
      yield put(transactionLineCollectionActions.show.byId.error(error));
      debugPrintAndLogError(error);
    }
  },
  *findByDocumentId(action) {
    try {
      const { organizationId = yield useCurrentOrganizationId(), documentId } = action.payload;
      const response = yield call(api.findByDocumentId, { organizationId, documentId });
      yield put(transactionLineCollectionActions.show.byDocumentId.success(response.data));
    } catch (error) {
      yield put(transactionLineCollectionActions.show.byDocumentId.error(error));
      debugPrintAndLogError(error);
    }
  },
  *destroy(action) {
    try {
      const { organizationId = yield useCurrentOrganizationId(), id } = action.payload;
      const response = yield call(api.destroy, { organizationId, id });
      yield put(transactionLineCollectionActions.destroy.success(response.data));
    } catch (error) {
      yield put(transactionLineCollectionActions.destroy.error(error));
      debugPrintAndLogError(error);
    }
  },

  transactionLine: {
    *update(action) {
      try {
        const {
          organizationId = yield useCurrentOrganizationId(),
          id,
          name,
          date,
          description,
          reference,
          amount,
          documentId,
          itemCategoryId,
          currencyCode,
          markUnmatched,
          onCompletion = () => {},
        } = action.payload;

        const response = yield call(api.updateTransactionLine, {
          organizationId,
          id,
          name,
          date,
          description,
          reference,
          amount,
          currencyCode,
          documentId,
          itemCategoryId,
          markUnmatched,
        });

        yield put(transactionLineCollectionActions.transactionLine.update.success(response.data));

        onCompletion(response.data);
      } catch (error) {
        yield put(transactionLineCollectionActions.transactionLine.update.error(error));
        debugPrintAndLogError(error);
      }
    },
    *updateMultiple(action) {
      try {
        const {
          organizationId = yield useCurrentOrganizationId(),
          ids,
          onCompletion = () => {},
        } = action.payload;

        const response = yield call(api.updateMultipleTransactionLines, {
          organizationId,
          ids,
          ...action.payload,
        });

        yield put(
          transactionLineCollectionActions.transactionLine.updateMultiple.success(response.data),
        );

        onCompletion(response.data);
      } catch (error) {
        yield put(transactionLineCollectionActions.transactionLine.updateMultiple.error(error));
        debugPrintAndLogError(error);
      }
    },
    *destroy(action) {
      try {
        const {
          organizationId = yield useCurrentOrganizationId(),
          id,
          onCompletion = () => {},
        } = action.payload;

        const response = yield call(api.destroyTransactionLine, {
          organizationId,
          id,
        });

        yield put(transactionLineCollectionActions.transactionLine.destroy.success(response.data));

        onCompletion(response.data);
      } catch (error) {
        yield put(transactionLineCollectionActions.transactionLine.destroy.error(error));
        debugPrintAndLogError(error);
      }
    },
    *convert(action) {
      try {
        const {
          organizationId = yield useCurrentOrganizationId(),
          id,
          skipConverted = true,
          mergeTactic = "merge_combine",
          onCompletion = () => {},
        } = action.payload;

        const response = yield call(api.convertTransactionLine, {
          organizationId,
          id,
          skipConverted,
          mergeTactic,
        });

        yield put(transactionLineCollectionActions.transactionLine.convert.success(response.data));

        onCompletion(response.data);
      } catch (error) {
        yield put(transactionLineCollectionActions.transactionLine.convert.error(error));
        debugPrintAndLogError(error);
      }
    },
    *convertMultiple(action) {
      try {
        const {
          organizationId = yield useCurrentOrganizationId(),
          ids,
          skipConverted = true,
          mergeTactic = "merge_combine",
          onCompletion = () => {},
        } = action.payload;

        const response = yield call(api.convertTransactionLines, {
          organizationId,
          ids,
          skipConverted,
          mergeTactic,
        });

        const { results } = response.data;

        yield put(
          transactionLineCollectionActions.transactionLine.convertMultiple.success(results),
        );

        onCompletion(results);
      } catch (error) {
        yield put(transactionLineCollectionActions.transactionLine.convertMultiple.error(error));
        debugPrintAndLogError(error);
      }
    },
  },
};
