import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import "./index.scss";
import App from "./App.jsx";
import reportWebVitals from "./reportWebVitals.js";
import { store, history } from "./store.js";

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: "https://055661aca1542991919db08cba26600b@o4506019591225344.ingest.sentry.io/4506303325732864",
    integrations: [],
  });
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<App store={store} history={history} />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
