import { call, put } from "redux-saga/effects";
import { get, omit } from "lodash";
import * as api from "./api";
import { userActions } from "./actions";
import { organizationActions } from "../organization/actions";
import { logAnalyticsEvent } from "../analytics/analyticsService";
import { setupLanguageAndRegionIfNeeded } from "../locales/sagas";
import { debugPrint, debugPrintAndLogError } from "../logging/logger";
import { session } from "../login/sagas";
import { logStartTrial } from "../tracking/facebookPixel";

export const userSagas = {
  *updateMe(action) {
    try {
      const {
        name,
        preferredCurrency,
        preferredTaxMode,
        webLocale,
        webLanguage,
        preferredDateFormat,
        preferredDateFormats,
        marketingConsent,
        enabledNotifications,
      } = action.payload;

      const response = yield call(api.updateMe, {
        name,
        preferredCurrency,
        preferredTaxMode,
        webLocale,
        webLanguage,
        preferredDateFormat,
        preferredDateFormats,
        marketingConsent,
        enabledNotifications,
      });

      logAnalyticsEvent("user_edited");

      yield put(organizationActions.users.list.request());
      yield put(userActions.updateMe.success(response.data));

      if (webLocale) {
        debugPrint("Reload language and locale.");
        setupLanguageAndRegionIfNeeded(response.data.user);
      }
    } catch (error) {
      debugPrintAndLogError(error);
      yield put(userActions.updateMe.error({ error }));
    }
  },
  *submitOnboarding(action) {
    try {
      const response = yield call(
        api.submitOnboarding,
        omit(action.payload || {}, ["onCompletion"]),
      );

      yield call(logAnalyticsEvent, "onboarding_submitted");

      if (get(action, "payload.useCases", []).includes("business")) {
        yield call(logStartTrial);
      }

      yield call(session.loginInfo);
      yield put(userActions.submitOnboarding.success(response.data));
    } catch (error) {
      debugPrintAndLogError(error);
      yield put(userActions.submitOnboarding.error({ error }));
    }
  },
  *updateOnboarding(action) {
    try {
      const response = yield call(api.updateOnboarding, action.payload);

      logAnalyticsEvent("onboarding_updated");

      yield put(userActions.updateOnboarding.success(response.data));

      if (action.payload?.onCompletion) {
        action.payload.onCompletion(true);
      }
    } catch (error) {
      debugPrintAndLogError(error);
      yield put(userActions.updateOnboarding.error({ error }));

      if (action.payload?.onCompletion) {
        action.payload.onCompletion(false);
      }
    }
  },
};
